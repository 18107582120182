import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'
import { Text, Flex, CardBody, CardFooter, useModal, Button, useToast } from '@pancakeswap/uikit'

const LaunchStyled = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;

  .LaunchConter {
    width: 100%;
    height: 100vh;
    padding: 0px 25px;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .LaunchDes {
    h1 {
      font-size: 17px;
      color: #4C4C4C;
      font-weight: 400;
    }
    h2 {
      color: #1E2026;
      font-size: 36px;
      font-weight: bold;
      margin-top: 10px;
    }
    h3 {
      font-size: 20px;
      color: #1E2026;
      font-weight: bold;
      margin-top: 15px;
    }
  }
  .LaunchImg {
    width: 130px;
    margin: auto;
  }
  .LaunchLink {
    background: linear-gradient(90deg, #F2E192, #D5BC76);
    border-radius: 50px;
    line-height: 50px;
    text-align: center;
    color: #1E2026;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .LaunchKind {
    font-size: 14px;
    color: #A1A1A1;
    line-height: 20px;
    margin-top: 10px;
    p {
      font-weight: bold;
    }
    span {
      display: block;
    }
  }
  ${({ theme }) => theme.mediaQueries.md} {
    .LaunchConter {
      max-width: 390px;
      height: 550px;
      position: fixed;
      left: 50%;
      top: 5%;
      margin-left: -190px;
    }
  }

`

const Launch: React.FC<React.PropsWithChildren> = () => {
  const { t, currentLanguage: { locale } } = useTranslation()

  setTimeout(() => {
    sessionStorage.setItem('TTLaunch', "true")
  }, 2000)

  return (
    <>
      <LaunchStyled>
        <Flex flexDirection="column" width="100%">
          <div className="LaunchConter">
            <div className="LaunchImg">
              <img src="/images/Launch.png" alt="" />
            </div>
            <div className="LaunchKind">
              <p>{t("Kind reminder:")}</p>
              <span>{t("Please be aware of the following matters")}</span>
              <span>{t("1. Cryptographic assets belong to high-risk industries. Please operate with caution. If there is a rise or fall trend after entering the market, you must bear the risk yourself. Please be aware.")}</span>
              <span>{t("2. In response to local policies, IP access to the following regions is prohibited: Chinese Mainland, Philippines, Japan, Nigeria.")}</span>
              <span>{t("3. Please be alert to common scams: high return mining/wealth management/authorization fraud/free air drop/Ponzi scam.")}</span>
            </div>
            <div className="LaunchLink">
              {t("Start Trading")}
            </div>
          </div>
        </Flex>
      </LaunchStyled>
    </>
  )
}

export default Launch
