import {
  MenuItemsType,
  EarnFillIcon,
  EarnIcon,
  DropdownMenuItems,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { NavHome, NavHomeActive, NavMarket, NavMarketActive, NavTrade, NavSwap, NavTradeActive, NavSwapActive, NavFarms, NavFarmsActive } from './navImgs'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

// 移动端底部导航配置 tabbar
const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    // {
    //   label: t('Trade'),
    //   icon: SwapIcon,
    //   fillIcon: SwapFillIcon,
    //   href: '/swap',
    //   showItemsOnMobile: false,
    //   items: [
    //     {
    //       label: t('Swap'),
    //       href: '/swap',
    //     },
    //     {
    //       label: t('Limit'),
    //       href: '/limit-orders',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //       image: '/images/decorations/3d-coin.png',
    //     },
    //     {
    //       label: t('Liquidity'),
    //       href: '/liquidity',
    //     },
    //     {
    //       label: t('Perpetual'),
    //       href: getPerpetualUrl({
    //         chainId,
    //         languageCode,
    //         isDark,
    //       }),
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //     {
    //       label: t('Bridge'),
    //       href: 'https://bridge.pancakeswap.finance/',
    //       type: DropdownMenuItemType.EXTERNAL_LINK,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Earn'),
    //   href: '/farms',
    //   icon: EarnIcon,
    //   fillIcon: EarnFillIcon,
    //   image: '/images/decorations/pe2.png',
    //   items: [
    //     {
    //       label: t('Farms'),
    //       href: '/farms',
    //     },
    //     {
    //       label: t('Pools'),
    //       href: '/pools',
    //       supportChainIds: SUPPORT_ONLY_BSC,
    //     },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Market'),
    //   href: '/bmarket',
    //   icon: NavMarket,
    //   fillIcon: NavMarketActive,
    //   showItemsOnMobile: false,
    //   image: '/images/dswap/nav-market.png',
    //   items: [
    //     // {
    //     //   label: t('Farms'),
    //     //   href: '/farms',
    //     // },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Infos'),
    //   href: 'https://m.avedex.cc/?utm_source=tokenpocket',
    //   icon: NavMarket,
    //   fillIcon: NavMarketActive,
    //   showItemsOnMobile: false,
    //   image: '../images/dswap/nav-trade.png',
    //   items: [
    //     // {
    //     //   label: t('Farms'),
    //     //   href: '/farms',
    //     // },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    // {
    //   label: t('Trade'),
    //   href: '/btrade',
    //   icon: NavTrade,
    //   fillIcon: NavTradeActive,
    //   showItemsOnMobile: false,
    //   image: '/images/dswap/nav-trade.png',
    //   items: [
    //     // {
    //     //   label: t('Farms'),
    //     //   href: '/farms',
    //     // },
    //   ].map((item) => addMenuItemSupported(item, chainId)),
    // },
    {
      label: t('Home'),
      href: '/home',
      icon: NavHome,
      fillIcon: NavHomeActive,
      showItemsOnMobile: false,
      image: '../images/dswap/nav-home.png',
      items: [
        // {
        //   label: t('home'),
        //   href: '/home',
        // },
        // {
        //   label: t('Pools'),
        //   href: '/pools',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Farms'),
      href: '/farms',
      icon: NavFarms,
      fillIcon: NavFarmsActive,
      showItemsOnMobile: false,
      image: '../images/dswap/nav-swap.png',
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Launchpad'),
      href: '/launchpad',
      icon: NavSwap,
      fillIcon: NavSwapActive,
      showItemsOnMobile: false,
      image: '../images/dswap/nav-swap.png',
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Swap'),
      href: '/swap',
      icon: NavTrade,
      fillIcon: NavTradeActive,
      showItemsOnMobile: false,
      image: '../images/dswap/nav-trade.png',
      items: [].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
