import styled from 'styled-components'
import { Button, ButtonProps } from '@pancakeswap/uikit'
import { useWallet } from 'hooks/useWallet'
// @ts-ignore
// eslint-disable-next-line import/extensions
import { useActiveHandle } from 'hooks/useEagerConnect.bmp.ts'
import Trans from './Trans'

const BHomeStyled = styled.div`
  position: absolute;
  left: 16px;
  top: 10px;
  cursor: pointer;
  ${({ theme }) => theme.mediaQueries.sm} {
    position: relative;
    left: 0px;
    margin-right: 10px;
  }
`

const ConnectWalletButtonMenu = ({ children, ...props }: ButtonProps) => {
  const handleActive = useActiveHandle()
  const { onPresentConnectModal } = useWallet()

  const handleClick = () => {
    if (typeof __NEZHA_BRIDGE__ !== 'undefined') {
      handleActive()
    } else {
      onPresentConnectModal()
    }
  }

  return (
    // menu 钱包
    <BHomeStyled onClick={handleClick} {...props}>
      {children || <Trans>Connect Wallet</Trans>}
    </BHomeStyled>
  )
}

export default ConnectWalletButtonMenu
