/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useWeb3React } from '@pancakeswap/wagmi'
import Web3 from 'web3'
import { createStore, Store } from 'redux'
import { timerReqEventName, timerResEventName, TTBASE_URL, TTTokenKey } from 'config/constants/api'

import { NextLinkFromReactRouter } from 'components/NextLink'
import { Menu as UikitMenu, useModal, useToast } from '@pancakeswap/uikit'
import { useTranslation, languageList } from '@pancakeswap/localization'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import useTheme from 'hooks/useTheme'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { usePhishingBannerManager } from 'state/user/hooks'
import axiosHelper, { eventEmitterBunny } from 'utils/request'
import { useMenuItems } from './hooks/useMenuItems'
import GlobalSettings from './GlobalSettings'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'
import { SettingsMode } from './GlobalSettings/types'

import UserMenu from './UserMenu'
import InviteCodeModal from './inviteCodeModal'
import LoginModal from './loginModal'
import Launch from '../../views/Launch'

let isFirstInto = true
const Menu = (props) => {
  const { isDark, setTheme } = useTheme()
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true })
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useRouter()
  const { provider } = useActiveWeb3React()
  const { account } = useWeb3React()
  const { toastError } = useToast()
  const [needInputCode, setNeedInputCode] = useState(true)
  // @ts-ignore
  const [handleInviteCode] = useModal(<InviteCodeModal confirmClick={signLogin} />, false)
  // @ts-ignore
  const [handleLogin] = useModal(<LoginModal confirmClick={signLogin} />, false)

  // const [showPhishingWarningBanner] = usePhishingBannerManager()
  const showPhishingWarningBanner = false

  const menuItems = useMenuItems()

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems as any, pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  useEffect(() => {
    if (account) {
      // console.log('menu on')

      const reqEvent = () => {
        // 用户登录Login
        if (account) {
          // console.log('menu toLoginSign timerReqEventName')
          // 只有power页面，需要登录
          // if(pathname && pathname.indexOf('power') === 1){
          //   checkUserAndSignLogin(account).then()
          // }
          checkUserAndSignLogin(account).then()
        }
      }
      // @ts-ignore
      eventEmitterBunny._events[timerReqEventName] = []
      eventEmitterBunny.on(timerReqEventName, reqEvent)
      // console.log('eventEmitterBunny===>', eventEmitterBunny)
      const resEvent = () => {
        if (account) {
          // console.log('menu toLoginSign timerResEventName')
          // if(pathname && pathname.indexOf('power') === 1){
          //   checkUserAndSignLogin(account).then()
          // }
          checkUserAndSignLogin(account).then()
        }
      }
      // @ts-ignore
      eventEmitterBunny._events[timerResEventName] = []
      eventEmitterBunny.on(timerResEventName, resEvent)
    }
  }, [account])

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? 'light' : 'dark')
  }, [setTheme, isDark])

  const getFooterLinks = useMemo(() => {
    return footerLinks(t)
  }, [t])

  useEffect(() => {
    console.log('account=======>', account)
    if (account) {
      const temp = {
        access_token: 'dyWsP3yNHl_R4fCcbupn8BI0000000000000000000',
        expires_in: '720',
      }
      // console.log('isFirstInto', isFirstInto)
      if (isFirstInto) {
        isFirstInto = false
      } else {
        // console.log('accountsChanged token null')
        localStorage.setItem(TTTokenKey, JSON.stringify(temp))
      }
      const localAccount = localStorage.getItem('TTACCOUNT')
      if (account && localAccount && account !== localAccount) {
        // console.log('accountsChanged account different')
        localStorage.setItem(TTTokenKey, JSON.stringify(temp))
      }
    }
  }, [account])

  useEffect(() => {
    async function getData() {
      try {
        // console.log('------------> check api')
        const url = `${TTBASE_URL}/user/api/v1/info/getOwner`
        const userInfo: any = await axiosHelper.get(url)
        // console.log('------------ check userInfo res', userInfo)
      } catch (error) {
        console.log('---> check api error', error)
      }
    }
    if (account) {
      getData().then()
    }
  }, [account])

  // useEffect(() => {
  //   async function loginData() {
  //     try {
  //       const tokenString = localStorage.getItem(TTTokenKey)
  //       if (!tokenString || tokenString.length <= 20) {
  //         console.log('account change to checkUserAndSignLogin', account)
  //         await checkUserAndSignLogin(account)
  //       }
  //     } catch (error: any) {
  //       console.error(error)
  //       toastError(t('Error'), error?.message || JSON.stringify(error))
  //     }
  //   }
  //   if (account) {
  //     loginData().then()
  //   }
  // }, [account, provider])

  async function checkUserAndSignLogin(accountStr: string) {
    // console.log('checkUserAndSignLogin=====>')
    // console.log(account, accountStr)
    // 有无用户
    const url = `${TTBASE_URL}/user/api/v1/check?address=${accountStr}`
    const checkUserRes: any = await axiosHelper.get(url)
    // console.log('checkUserRes', checkUserRes)
    if (checkUserRes && checkUserRes.code === 200) {
      // console.log(`our user ${accountStr}`, checkUserRes.data)
      if (checkUserRes.data === false) {
        // 不是我们用户 填写邀请码
        // inviteCode: 'EPD9AMJc7gu',
        // console.log('go input invite code login')
        handleInviteCode()
      } else {
        // 是我们用户
        // console.log('go login')
        handleLogin()
        // await signLogin()
      }
    } else {
      toastError(t('Error'), checkUserRes?.data?.msg || JSON.stringify(checkUserRes))
    }
  }

  async function signLogin(inviteCode = '') {
    const time = new Date().getTime()
    let loginForm = {
      address: account,
      randomHex: '',
      time,
      inviteCode,
      r: '',
      s: '',
      v: '',
    }
    const ethereumHelper = (window as any).ethereum || false
    if (provider && ethereumHelper && account) {
      const nodeurl = (provider as any)?.connection?.url || ''
      const web3 = new Web3(ethereumHelper)
      const randomHex = web3.utils.randomHex(32)
      const loginInfoStr = account + randomHex + time
      const hash = web3.utils.keccak256(loginInfoStr)
      // @ts-ignore
      const signature = await web3.eth.personal.sign(hash, account, (err: any) => {
        if (err) {
          console.error(err)
        }
      })
      const signature1 = signature.substring(2)
      const r = `0x${signature1.substring(0, 64)}`
      const s = `0x${signature1.substring(64, 128)}`
      const v = `0x${signature1.substring(128, 130)}`
      loginForm = {
        ...loginForm,
        randomHex,
        r,
        s,
        v,
      }
      // console.log('loginForm', loginForm)
      const url = `${TTBASE_URL}/user/api/v1/login`
      const res: any = await axiosHelper.post(url, loginForm)
      // console.log('loginFormRes', res)
      if (res.code === 200) {
        // console.log('set TTACCOUNT account', account)
        localStorage.setItem(TTTokenKey, JSON.stringify(res.data))
        localStorage.setItem('TTACCOUNT', account)
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload()
        }, 500)
      } else {
        toastError(t('Error'), JSON.stringify(res?.msg || res))
      }
    }
  }

  // 启动页
  const [launch, setLaunch] = useState(sessionStorage.getItem('TTLaunch'))
  setTimeout(() => {
    setLaunch(sessionStorage.getItem('TTLaunch')) 
  }, 2500)
  if(launch == null && pathname.indexOf('add') !== 1){
    return <Launch />
  }


  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
        }}
        leftSide={
          <>
            {/* 链接钱包 当作左侧user图标 */}
            <UserMenu />
          </>
        }
        rightSide={
          <>
            {/* 切换链路 */}
            <NetworkSwitcher />
            <img className="rightSideImg scanImg" src="../images/dswap/scanner.png" alt="" />
            <img className="rightSideImg kefu" src="../images/dswap/headset.png" alt="" />
            <img className="rightSideImg notify" src="../images/dswap/notification.png" alt="" />
            <img className="rightSideImg payImg" src="../images/dswap/pay.png" alt="" />
            {/* 头部导航 */}
            {/* 头部设置 */}
            {/* <GlobalSettings mode={SettingsMode.GLOBAL} /> */}
            {/* 链接钱包 右侧 */}
            {/* <UserMenu /> */}
          </>
        }
        banner={showPhishingWarningBanner && typeof window !== 'undefined' && <PhishingWarningBanner />}
        isDark={isDark}
        toggleTheme={toggleTheme}
        // currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t('Buy BUY')}
        {...props}
      />
    </>
  )
}

export default Menu
