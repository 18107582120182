import BigNumber from 'bignumber.js'
import { BLOCKS_PER_YEAR } from 'config'
import lpAprs56 from 'config/constants/lpAprs/56.json'


const getLpApr = (chainId: number) => {
  switch (chainId) {
    case 56:
      return lpAprs56
    default:
      return {}
  }
}

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * 获取农场APR值 - pancakeswap（%）
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
// export const getFarmApr = (
//   chainId: number,
//   poolWeight: BigNumber,
//   cakePriceUsd: BigNumber,
//   poolLiquidityUsd: BigNumber,
//   farmAddress: string,
//   regularCakePerBlock: number,
// ): { cakeRewardsApr: number; lpRewardsApr: number } => {
//   const yearlyCakeRewardAllocation = poolWeight
//     ? poolWeight.times(BLOCKS_PER_YEAR * regularCakePerBlock)
//     : new BigNumber(NaN)
//   const cakeRewardsApr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)

//   let cakeRewardsAprAsNumber = null

//   if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
//     cakeRewardsAprAsNumber = cakeRewardsApr.toNumber()
//   }
//   const lpRewardsApr = getLpApr(chainId)[farmAddress?.toLowerCase()] ?? 0
//   // console.log('cakeRewardsAprAsNumber====>', cakeRewardsAprAsNumber)
//   // console.log('farmAddress====>', farmAddress)
  
//   return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
// }

/**
 * 获取农场APR值（%）
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd BNB price in USD BNB价格（美元）
 * @param poolLiquidityUsd Total pool liquidity in USD 总池流动性（美元）
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  chainId: number,
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  filPriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
  regularCakePerBlock: number,
  pid: number,
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  // console.log('pid=====>', pid)
  // console.log('cakePriceUsd=====>', cakePriceUsd.toNumber())
  // console.log('filPriceUsd=====>', filPriceUsd.toNumber())

  // APR计算方法
  // (一年的区块高度 * 每个区块产出币数量:合约cakePerBlock方法) * 产出币的USDT价格：比如产出BNB，就是1个BNB兑换USDT的价格,调用cakePriceUsd方法) / 质押总流动性价值(U):调用poolLiquidityUsd方法 * 100
  let cakeRewardsApr = 0

  // 暂时先写死 9.24
  // 880948922967731105762
  // 6532.8933 / 880.948922967731105762 = 7.415745882922039
  // const liquidityPid1 = poolLiquidityUsd ? poolLiquidityUsd.toNumber() - 6532 : 0
  // const liquidityPid0 = poolLiquidityUsd ? poolLiquidityUsd.toNumber() - liquidityPid1 : 0

  // 495172040867512171893
  // 3971.477829616 / 495.172040867512171893 = 8.0204
  // 691184365987373081768
  // 5543.575040944 / 691.184365987373081768 = 8.0204
  const liquidityPid2 = poolLiquidityUsd ? poolLiquidityUsd.toNumber() - 6164 : 0
  const liquidityPid1 = poolLiquidityUsd ? poolLiquidityUsd.toNumber() - (3389 + liquidityPid2) : 0
  const liquidityPid0 = poolLiquidityUsd ? poolLiquidityUsd.toNumber() - (2774 + liquidityPid2) : 0

  // console.log('liquidityPid1==>', liquidityPid1)
  // console.log('liquidityPid0==>', liquidityPid0)
  // console.log('ccc====>', poolLiquidityUsd.toNumber())

  if (pid === 0) {
    // cakeRewardsApr = (BLOCKS_PER_YEAR * 0.000017361111111111) * cakePriceUsd.toNumber() / poolLiquidityUsd.toNumber() * 100
    cakeRewardsApr = (BLOCKS_PER_YEAR * 0.000017361111111111) * cakePriceUsd.toNumber() / liquidityPid0 * 100
  } else if (pid === 1) {
    // cakeRewardsApr = (BLOCKS_PER_YEAR * 0.000347222222222222) * filPriceUsd.toNumber() / poolLiquidityUsd.toNumber() * 100
    cakeRewardsApr = (BLOCKS_PER_YEAR * 0.000347222222222222) * filPriceUsd.toNumber() / liquidityPid1 * 100
  } else if (pid === 2) {
    // cakeRewardsApr = (BLOCKS_PER_YEAR * 0.000347222222222222) * filPriceUsd.toNumber() / poolLiquidityUsd.toNumber() * 100
    cakeRewardsApr = (BLOCKS_PER_YEAR * 0.000347222222222222) * filPriceUsd.toNumber() / liquidityPid2 * 100
  }

  let cakeRewardsAprAsNumber = null
  if (cakeRewardsApr) {
    cakeRewardsAprAsNumber = cakeRewardsApr
  }
  const lpRewardsApr = getLpApr(chainId)[farmAddress?.toLowerCase()] ?? 0
  // console.log('cakeRewardsAprAsNumber====>', cakeRewardsAprAsNumber)
  
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
}

export default null
